import { styled } from '@attentive/picnic';

import { clientUIMinWidth } from '../constants';

export const LayoutGrid = styled('div', {
  display: 'grid',
  width: '100%',
  height: '100%',
  minWidth: clientUIMinWidth,
  gridTemplateColumns: `min-content minmax(0, 1fr)`,
  gridTemplateRows: `min-content min-content minmax(0, 1fr)`,
  gridTemplateAreas: `
    "notifications notifications"
    "sidebar topbar"
    "sidebar content"`,
});

export const NotificationsArea = styled('div', {
  gridArea: 'notifications',
  zIndex: '$layer1',
  background: '$bgDefault',
});

export const TopbarArea = styled('div', {
  gridArea: 'topbar',
  zIndex: '$layer1',
  background: '$bgDefault',
});

export const ContentArea = styled('main', {
  gridArea: 'content',
  position: 'relative',
  overflow: 'auto',
});

export const SidebarArea = styled('div', {
  gridArea: 'sidebar',
  zIndex: '$layer1',
});
