/**
 * @generated SignedSource<<ee80bff180ac39e99485f935c047b561>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ButtonAction = "BUTTON_ACTION_CLOSE" | "BUTTON_ACTION_LINK" | "BUTTON_ACTION_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useActiveDismissibleModalFragment_query$data = {
  readonly dismissibleModals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly endTime: SerializedDateTime;
        readonly featureFlags: ReadonlyArray<string>;
        readonly id: string;
        readonly imageUrl: string | null;
        readonly modalId: string;
        readonly primaryButtonAction: ButtonAction;
        readonly primaryButtonText: string;
        readonly primaryButtonUrl: string | null;
        readonly secondaryButtonAction: ButtonAction | null;
        readonly secondaryButtonText: string | null;
        readonly secondaryButtonUrl: string | null;
        readonly startTime: SerializedDateTime;
        readonly title: string | null;
        readonly url: string;
      };
    }>;
  } | null;
  readonly " $fragmentType": "useActiveDismissibleModalFragment_query";
};
export type useActiveDismissibleModalFragment_query$key = {
  readonly " $data"?: useActiveDismissibleModalFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useActiveDismissibleModalFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useActiveDismissibleModalFragment_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Literal",
              "name": "activeOnly",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "companyId",
              "variableName": "companyId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "DismissibleModalConnection",
      "kind": "LinkedField",
      "name": "dismissibleModals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DismissibleModalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DismissibleModal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryButtonAction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryButtonText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryButtonUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secondaryButtonAction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secondaryButtonText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secondaryButtonUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "modalId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "featureFlags",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7c4e5725fc8575a8f3afbbdccadc95fe";

export default node;
