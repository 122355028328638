import React, { useEffect, useState } from 'react';
import {
  Text,
  Button,
  TextInput,
  FormField,
  Stack,
  Box,
  Separator,
  Heading,
} from '@attentive/picnic';
import { AuthSession, GRAPHQL_API_ORIGIN_KEY, graphqlApiOriginAtom } from '@attentive/acore-utils';
import { useAtomValue } from 'jotai';

const DEV_LOCAL_URL = 'http://localhost:5002';

export const ApiTools: React.VFC = () => {
  const graphqlApiUrl = useAtomValue(graphqlApiOriginAtom);

  const [graphqlOrigin, setGraphqlOrigin] = useState(DEV_LOCAL_URL);
  const [authToken, setAuthToken] = useState<string | null>(null);

  const handleClick = () => {
    const newLocation = new URL(window.location.href);
    newLocation.searchParams.set(GRAPHQL_API_ORIGIN_KEY, graphqlOrigin);
    window.location.href = newLocation.toString();
  };

  const handleCopyToken = () => {
    const token = AuthSession.retrieveToken();
    if (!token) {
      return;
    }
    navigator.clipboard.writeText(token);
  };

  useEffect(() => {
    setAuthToken(AuthSession.retrieveToken());
  }, []);

  return (
    <Stack>
      <Heading variant="md">Graphql API hostname override</Heading>
      <Text>Current Graphql API hostname:</Text>
      <Box as="code" css={{ background: '$bgAccent', p: '$space1', mt: '$space1' }}>
        {graphqlApiUrl}
      </Box>
      <FormField>
        <FormField.Label
          css={{
            fontWeight: '$regular',
          }}
        >
          New Graphql API hostname:
        </FormField.Label>
        <TextInput value={graphqlOrigin} onChange={(e) => setGraphqlOrigin(e.target.value)} />
      </FormField>
      <Button onClick={handleClick}>Refresh page with new hostname</Button>

      <Separator />

      <Heading variant="md">Auth token</Heading>
      {authToken && (
        <Text
          as="code"
          css={{
            background: '$bgAccent',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            p: '$space1 $space2',
          }}
          variant="micro"
        >
          {authToken}
        </Text>
      )}
      <Button onClick={handleCopyToken}>Copy Auth Token To Clipboard</Button>
    </Stack>
  );
};
