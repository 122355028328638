/**
 * @generated SignedSource<<830e90f61adea319cfac51c2011bab8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeLayoutClientUIQuery$variables = {
  companyId: string;
};
export type ConciergeLayoutClientUIQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTopBarClientUIQueryFragment_query">;
};
export type ConciergeLayoutClientUIQuery = {
  response: ConciergeLayoutClientUIQuery$data;
  variables: ConciergeLayoutClientUIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConciergeLayoutClientUIQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ConciergeTopBarClientUIQueryFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConciergeLayoutClientUIQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ConciergeAgent",
                "kind": "LinkedField",
                "name": "conciergeAgent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAvailable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEnrolledIntoChats",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GetRolesResponse",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "scopes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateRequestConnection",
        "kind": "LinkedField",
        "name": "agentTemplateRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fef3d5504c86dea516abe46ebe4a0a6",
    "id": null,
    "metadata": {},
    "name": "ConciergeLayoutClientUIQuery",
    "operationKind": "query",
    "text": "query ConciergeLayoutClientUIQuery(\n  $companyId: ID!\n) {\n  ...ConciergeTopBarClientUIQueryFragment_query\n}\n\nfragment AccountNameClientUIFragment_account on Account {\n  firstName\n  lastName\n}\n\nfragment ConciergeAvailabilityToggleFragment_account on Account {\n  id\n  conciergeAgent {\n    isAvailable\n    isEnrolledIntoChats\n  }\n}\n\nfragment ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query on Query {\n  agentTemplateRequests {\n    totalCount\n  }\n}\n\nfragment ConciergeTopBarClientUIQueryFragment_query on Query {\n  viewer {\n    account {\n      ...ConciergeAvailabilityToggleFragment_account\n      ...ConciergeTopBarDropdownMenuClientUIFragment_account\n      id\n    }\n    roles(companyId: $companyId) {\n      roles\n    }\n    scopes(companyId: $companyId)\n  }\n  ...ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query\n}\n\nfragment ConciergeTopBarDropdownMenuClientUIFragment_account on Account {\n  ...AccountNameClientUIFragment_account\n}\n"
  }
};
})();

(node as any).hash = "58ee37e2970f1dfce7a84aafa5ccc7b1";

export default node;
