/**
 * @generated SignedSource<<89bd934e4780f7c43477ef85f385c88b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type Scope = "SCOPE_ALL_ACCOUNTS" | "SCOPE_ALL_CONCIERGE_SETTINGS" | "SCOPE_ALL_COUPONS" | "SCOPE_ALL_CUSTOM_ATTRIBUTES" | "SCOPE_ALL_CUSTOM_EVENTS" | "SCOPE_ALL_ECOMMERCE_EVENTS" | "SCOPE_ALL_IDENTITY" | "SCOPE_ALL_MESSAGES" | "SCOPE_ALL_OFFERS" | "SCOPE_ALL_POLLS" | "SCOPE_ALL_PRIVACY_REQUESTS" | "SCOPE_ALL_PRODUCT_CATALOGS" | "SCOPE_ALL_PROVISION" | "SCOPE_ALL_SCIM" | "SCOPE_ALL_SUBSCRIBER_EVENTS" | "SCOPE_ALL_WEBHOOKS" | "SCOPE_AUDIT_SUBSCRIPTIONS" | "SCOPE_UNKNOWN" | "SCOPE_VIEW_POLLS" | "SCOPE_VIEW_PRIVACY_REQUESTS" | "SCOPE_VIEW_PRODUCT_CATALOGS" | "SCOPE_VIEW_PROVISION" | "SCOPE_VIEW_SCIM" | "SCOPE_VIEW_SUBSCRIBER_EVENTS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuthorizationDataProviderQueryFragment_query$data = {
  readonly viewer: {
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
    readonly scopes: ReadonlyArray<Scope> | null;
  } | null;
  readonly " $fragmentType": "AuthorizationDataProviderQueryFragment_query";
};
export type AuthorizationDataProviderQueryFragment_query$key = {
  readonly " $data"?: AuthorizationDataProviderQueryFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationDataProviderQueryFragment_query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AuthorizationDataProviderQueryFragment_query"
};

(node as any).hash = "5cd1b159d38187ca9776b1db35caa9f4";

export default node;
