import { styled } from '@attentive/picnic';

export const AdminLayoutGrid = styled('div', {
  display: 'grid',
  width: '100%',
  height: '100%',
  gridTemplateColumns: `min-content minmax(0, 1fr)`,
  gridTemplateRows: `min-content min-content minmax(0, 1fr)`,
  gridTemplateAreas: `
    "notifications notifications"
    "sidebar topbar"
    "sidebar content"`,
});

export const AdminNotificationsArea = styled('div', {
  gridArea: 'notifications',
  zIndex: '$layer1',
});

export const AdminTopbarArea = styled('div', {
  gridArea: 'topbar',
  zIndex: '$layer1',
  background: '$bgDefault',
});

export const AdminContentArea = styled('main', {
  gridArea: 'content',
  position: 'relative',
});

export const AdminSidebarArea = styled('div', {
  gridArea: 'sidebar',
  zIndex: '$layer1',
});
