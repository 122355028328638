/**
 * @generated SignedSource<<38b5e3366093ae599bb8a407547da86a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery$variables = Record<PropertyKey, never>;
export type ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query">;
};
export type ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery = {
  response: ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery$data;
  variables: ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateRequestConnection",
        "kind": "LinkedField",
        "name": "agentTemplateRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b84c19e1e8001c3c7def69385ba48e5c",
    "id": null,
    "metadata": {},
    "name": "ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query ConciergeTemplateRequestsCountBadgeClientUIQueryFragmentRefetchQuery {\n  ...ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query\n}\n\nfragment ConciergeTemplateRequestsCountBadgeClientUIQueryFragment_query on Query {\n  agentTemplateRequests {\n    totalCount\n  }\n}\n"
  }
};

(node as any).hash = "0a455252d7d0f232b01189cc4c66232f";

export default node;
