/**
 * @generated SignedSource<<006e1f4608ced7d348b48267ef43fe9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type MilestoneKey = "MILESTONE_KEY_ADDED_USER_ACCOUNTS" | "MILESTONE_KEY_COMPLETED_BRAND_STYLES" | "MILESTONE_KEY_COMPLETED_COMPANY_FORM" | "MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY" | "MILESTONE_KEY_COMPLETED_ONBOARDING" | "MILESTONE_KEY_COMPLETED_RTL_CREATIVE" | "MILESTONE_KEY_COMPLETED_WELCOME_JOURNEY" | "MILESTONE_KEY_DEFAULT_OFFER_REVIEWED" | "MILESTONE_KEY_ECOMMERCE_PLATFORM_COMPLETED" | "MILESTONE_KEY_EMAIL_CTA_FIRST_CLICKED" | "MILESTONE_KEY_EMAIL_FIRST_SENT" | "MILESTONE_KEY_ESP_INTEGRATION_COMPLETED" | "MILESTONE_KEY_IMPORTED_FROM_SALESFORCE" | "MILESTONE_KEY_INSTALLED_TAG" | "MILESTONE_KEY_SALESFORCE_CONVERTED_TO_PAYING" | "MILESTONE_KEY_SALESFORCE_GO_LIVE" | "MILESTONE_KEY_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientUIMilestonesFragment_company$data = {
  readonly milestones: {
    readonly milestones: ReadonlyArray<{
      readonly completed: SerializedDateTime | null;
      readonly key: MilestoneKey;
    }>;
  } | null;
  readonly " $fragmentType": "ClientUIMilestonesFragment_company";
};
export type ClientUIMilestonesFragment_company$key = {
  readonly " $data"?: ClientUIMilestonesFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientUIMilestonesFragment_company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ClientUIMilestonesFragment_company"
};

(node as any).hash = "bf0413e6db2bff66ec66a6e9592c7ea5";

export default node;
