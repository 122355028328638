/**
 * @generated SignedSource<<2d6530c1c08cb1ae99c7c5d9b88c6cf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegionDesignation = "REGION_DESIGNATION_DEFAULT" | "REGION_DESIGNATION_OTHER" | "REGION_DESIGNATION_UNKNOWN" | "%future added value";
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type CompanyTransitionClientUIQuery$variables = {
  companyId: string;
};
export type CompanyTransitionClientUIQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppCurrentUserClientUiFragment_query">;
};
export type CompanyTransitionClientUIQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly attentiveDomain: string;
    readonly businessHours: {
      readonly close: string;
      readonly open: string;
      readonly timezone: string;
    };
    readonly country: string;
    readonly csTier: string | null;
    readonly displayName: string;
    readonly domain: string | null;
    readonly externalId: number;
    readonly id: string;
    readonly internalId: number;
    readonly name: string;
    readonly regions: ReadonlyArray<{
      readonly databaseId: number;
      readonly designation: RegionDesignation;
      readonly id: string;
    }> | null;
    readonly status: string;
    readonly timezone: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly id: string;
      readonly internalId: number;
      readonly lastName: string;
      readonly phone: string;
    } | null;
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
  } | null;
};
export type CompanyTransitionClientUIQuery = {
  rawResponse: CompanyTransitionClientUIQuery$rawResponse;
  response: CompanyTransitionClientUIQuery$data;
  variables: CompanyTransitionClientUIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyTransitionClientUIQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AppCurrentUserClientUiFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyTransitionClientUIQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "companyId",
                "variableName": "companyId"
              }
            ],
            "concreteType": "GetRolesResponse",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attentiveDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "csTier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessHours",
                "kind": "LinkedField",
                "name": "businessHours",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "open",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "close",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "designation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7bb424b939a3d55a38b3badddca19c8d",
    "id": null,
    "metadata": {},
    "name": "CompanyTransitionClientUIQuery",
    "operationKind": "query",
    "text": "query CompanyTransitionClientUIQuery(\n  $companyId: ID!\n) {\n  ...AppCurrentUserClientUiFragment_query\n}\n\nfragment AppCurrentUserClientUiFragment_query on Query {\n  viewer {\n    account {\n      id\n      internalId\n      firstName\n      lastName\n      phone\n      email\n    }\n    roles(companyId: $companyId) {\n      roles\n    }\n  }\n  company: node(id: $companyId) {\n    id\n    __typename\n    ... on Company {\n      id\n      internalId\n      name\n      displayName\n      externalId\n      domain\n      attentiveDomain\n      status\n      timezone\n      country\n      csTier\n      businessHours {\n        open\n        close\n        timezone\n      }\n      regions {\n        designation\n        databaseId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9db81deb24e18f576d726fde559dac09";

export default node;
