import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { CompanyFeatureFlagNames, RoutePermissions } from '@attentive/acore-utils';
import { Permission, Role } from '@attentive/data';
import { RoutesFn } from '@attentive/data-router';
import { ProductFeedsListPageEntryPoint } from '@attentive/products/entrypoints';
import { CustomAttributesListEntryPoint } from '@attentive/targeting-common/entrypoints';
import { FeatureFlagsPageEntryPoint } from '@attentive/feature-flags/entrypoints';
import {
  SyncConfigurationDialogEntryPoint,
  SyncDetailEntryPoint,
  SyncListEntryPoint,
  SyncRunDetailEntryPoint,
} from '@attentive/eds/entrypoints';

import { SettingsPageRoute } from './SettingsPageRoute';
import { SectionName } from '../shared/constants/routes';
import {
  SubscriberUploadDialogEntryPoint,
  SubscriberUploadListEntryPoint,
  UploadDetailEntryPoint,
} from '../sections/subscriber-upload';
import {
  SuppressionListTabEntryPoint,
  ListMaintenanceTabEntryPoint,
  EmailListMaintenanceEntryPoint,
} from '@attentive/crm/entrypoints';
import { MessageSettingsPageEntryPoint } from '../sections/message-settings/MessageSettingsPage.entrypoint';
import { ConciergeSettingsPageEntryPoint } from '../sections/concierge-settings/ConciergeSettingsPage.entrypoint';
import { SftpAccountSettingsPageEntryPoint } from '../sections/sftp-account-settings/SftpAccountSettingsPage.entrypoint';
import { UserAccountManagementPageEntryPoint } from '../sections/user-account-management/UserAccountManagementPage.entrypoint';
import { EmailSettingsPageEntryPoint } from '../sections/email-settings/EmailSettingsPage/EmailSettingsPage.entrypoint';
import { AffiliateUserManagementPageEntryPoint } from '../sections/affiliate-user-management/AffiliateUserManagementPage.entrypoint';
import { LinkTrackingPageEntrypoint } from '../sections/link-tracking/LinkTrackingPage.entrypoint';
import { AIJourneySettingsPageEntryPoint } from '../sections/ai-journey-settings/AIJourneySettingsPage.entrypoint';
import { AIJourneySettingsFallbackMessagesEntryPoint } from '../sections/ai-journey-settings/AIJourneySettingsFallbackMessages.entrypoint';
import { CustomCompanyKeywordsPage } from '../sections/custom-company-keywords';
import { AIJourneySettingsPersonalizedMessagesPageEntryPoint } from '../sections/ai-journey-settings/AIJourneySettingsPeronalizedMessagesPage.entrypoint';

const routes: RoutesFn<CompanyFeatureFlagNames> = ({ getCompanyFeatureFlag, getRoles }) => {
  const roles = getRoles();
  const ENABLE_SUBSCRIBER_UPLOAD = getCompanyFeatureFlag('ENABLE_SUBSCRIBER_UPLOAD');
  const ENABLE_EMAIL_MGMT_PAGES = getCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const ENABLE_EMAIL_LIST_CLEANING =
    getCompanyFeatureFlag('ENABLE_EMAIL_LIST_CLEANING') && ENABLE_EMAIL_MGMT_PAGES;
  const ENABLE_PRODUCT_DATA = getCompanyFeatureFlag('ENABLE_PRODUCT_DATA');
  const ENABLE_PRODUCTS =
    getCompanyFeatureFlag('ENABLE_PRODUCT_FEEDS_UI') &&
    ENABLE_PRODUCT_DATA &&
    ENABLE_EMAIL_MGMT_PAGES;
  const ENABLE_EDS_UI = getCompanyFeatureFlag('ENABLE_EDS_UI');
  const ENABLE_ACCOUNT_MANAGEMENT = !roles.has(Role.RoleClientManagedAccount);

  const ONBOARDING_REGION_MGMT = getCompanyFeatureFlag('ONBOARDING_REGION_MGMT');
  const ENABLE_COGNITO_LOGIN = getCompanyFeatureFlag('ENABLE_COGNITO_SFTP_LOGIN');
  const ENABLE_UNIFIED_COMPANY_SETTINGS = getCompanyFeatureFlag('ENABLE_UNIFIED_COMPANY_SETTINGS');
  const ENABLE_COMPANY_INFO_BLOCKS_SETTINGS_UI = getCompanyFeatureFlag(
    'ENABLE_COMPANY_INFO_BLOCKS_SETTINGS_UI'
  );

  return (
    <Route Component={React.lazy(() => import('../SettingsMfe'))}>
      <Route path="" element={<Navigate replace={true} to="company-settings" />} />

      <Route path="coupons" element={<Navigate replace={true} to="/offers" />} />

      <Route element={<SettingsPageRoute sectionName={SectionName.COMPANY_SETTINGS} />}>
        <Route
          path="company-settings/*"
          Component={React.lazy(() => import('../sections/company-settings'))}
        />
      </Route>

      <Route element={<RoutePermissions permission={() => ONBOARDING_REGION_MGMT} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.COMPANY_OVERVIEW} />}>
          <Route
            path="overview"
            Component={React.lazy(() => import('../sections/company-overview/CompanyOverviewPage'))}
          />
        </Route>
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={() => ONBOARDING_REGION_MGMT && !ENABLE_UNIFIED_COMPANY_SETTINGS}
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.COMPANY_REGIONS} />}>
          <Route path="regions">
            <Route
              path=":region/*"
              Component={React.lazy(() => import('../sections/company-regions/RegionSettingsPage'))}
            />
            <Route
              index
              Component={React.lazy(() => import('../sections/company-regions/RegionsListPage'))}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<SettingsPageRoute sectionName={SectionName.REMOVE_SUBSCRIBER} />}>
        <Route
          path="opt-out-subscriber"
          Component={React.lazy(
            () => import('../sections/remove-subscriber/RemoveASubscriberPage')
          )}
        />
      </Route>

      <Route element={<SettingsPageRoute sectionName={SectionName.LINK_TRACKING} />}>
        <Route path="link-tracking" {...LinkTrackingPageEntrypoint} />
      </Route>

      <Route element={<SettingsPageRoute sectionName={SectionName.CUSTOM_ATTRIBUTES_SETTINGS} />}>
        <Route path="custom-attributes" {...CustomAttributesListEntryPoint} />
      </Route>

      <Route element={<RoutePermissions permission={() => ENABLE_PRODUCTS} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.PRODUCTS} />}>
          <Route path="products">
            <Route path="feeds" {...ProductFeedsListPageEntryPoint} />
            <Route path="" element={<Navigate to="feeds" />} />
          </Route>
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={() => ENABLE_EDS_UI} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.EDS} />}>
          <Route path="data-sync">
            <Route path="syncs" {...SyncListEntryPoint} />
            <Route path="create" {...SyncConfigurationDialogEntryPoint} />
            <Route path=":id/:activeSyncDetailTab/:syncRunId" {...SyncRunDetailEntryPoint} />
            <Route path=":id/:activeSyncDetailTab" {...SyncDetailEntryPoint} />
            <Route path=":id" element={<Navigate to="./details" />} />
            <Route path=":id/edit" {...SyncConfigurationDialogEntryPoint} />
            <Route path="" element={<Navigate to="syncs" />} />
          </Route>
        </Route>
      </Route>

      <Route element={<SettingsPageRoute sectionName={SectionName.CONNECTED_APPS} />}>
        <Route
          path="connected-apps"
          Component={React.lazy(() => import('../sections/connected-apps/ConnectedAppsPage'))}
        />
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={(checkPermission) =>
              !ONBOARDING_REGION_MGMT ||
              checkPermission(Permission.SuperUserAccess) ||
              (ONBOARDING_REGION_MGMT && ENABLE_UNIFIED_COMPANY_SETTINGS)
            }
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.MESSAGE_SETTINGS} />}>
          <Route path="message-settings" {...MessageSettingsPageEntryPoint} />
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={Permission.SuperUserAccess} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.LEGAL} />}>
          <Route path="legal" Component={React.lazy(() => import('../sections/legal/LegalPage'))} />
        </Route>

        <Route element={<SettingsPageRoute sectionName={SectionName.ADMIN_SETTINGS} />}>
          <Route
            path="admin-settings/*"
            Component={React.lazy(() => import('../sections/admin-settings'))}
          />
        </Route>

        <Route element={<SettingsPageRoute sectionName={SectionName.SSO_MANAGEMENT} />}>
          <Route
            path="sso-management"
            Component={React.lazy(() => import('../sections/sso-management/SSOManagementPage'))}
          />
        </Route>

        <Route element={<SettingsPageRoute sectionName={SectionName.MESSAGING_SERVICE_SETTINGS} />}>
          <Route
            path="messaging-service-settings"
            Component={React.lazy(
              () => import('../sections/message-service-settings/MessagingServiceSettingsPage')
            )}
          />
        </Route>

        <Route element={<RoutePermissions permission={() => ENABLE_SUBSCRIBER_UPLOAD} />}>
          <Route
            path="subscriber-upload"
            element={<SettingsPageRoute sectionName={SectionName.SUBSCRIBER_UPLOAD} />}
          >
            <Route path="history" {...SubscriberUploadListEntryPoint} />
            <Route path="create" {...SubscriberUploadDialogEntryPoint} />
            <Route path=":id" {...UploadDetailEntryPoint} />
            <Route path="" element={<Navigate replace={true} to="history" />} />
          </Route>
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={Permission.ConciergeSettingsAccess} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.CONCIERGE_SETTINGS} />}>
          <Route path="concierge-settings" {...ConciergeSettingsPageEntryPoint} />
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={Permission.AdminUIAccess} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.AFFILIATE_USER_MANAGEMENT} />}>
          <Route path="affiliate-user-management" {...AffiliateUserManagementPageEntryPoint} />
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={() => ENABLE_EMAIL_MGMT_PAGES} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.EMAIL_SETTINGS} />}>
          <Route path="email-settings" {...EmailSettingsPageEntryPoint} />
        </Route>
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={(checkPermissions) => checkPermissions(Permission.SuperUserAccess)}
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.AI_JOURNEY_SETTINGS} />}>
          <Route path="ai-journey-settings" {...AIJourneySettingsPageEntryPoint} />
        </Route>
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={(checkPermissions) => checkPermissions(Permission.SuperUserAccess)}
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.AI_JOURNEY_SETTINGS} />}>
          <Route
            path="ai-journey-settings/fallback-messages"
            {...AIJourneySettingsFallbackMessagesEntryPoint}
          />
        </Route>
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={(checkPermissions) =>
              ENABLE_COMPANY_INFO_BLOCKS_SETTINGS_UI && checkPermissions(Permission.SuperUserAccess)
            }
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.AI_JOURNEY_SETTINGS} />}>
          <Route
            path="ai-journey-settings/personalized-messages"
            {...AIJourneySettingsPersonalizedMessagesPageEntryPoint}
          />
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={() => ENABLE_EMAIL_LIST_CLEANING} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.EMAIL_SUPPRESSION_LIST} />}>
          <Route {...EmailListMaintenanceEntryPoint}>
            <Route path="email-suppression" {...SuppressionListTabEntryPoint} />
            <Route
              element={
                <RoutePermissions
                  permission={(checkPermission) =>
                    checkPermission(Permission.EmailListMaintenanceAccess)
                  }
                />
              }
            >
              <Route path="email-list-maintenance" {...ListMaintenanceTabEntryPoint} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<RoutePermissions permission={() => ENABLE_ACCOUNT_MANAGEMENT} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.USER_ACCOUNT_MANAGEMENT} />}>
          <Route path="user-account-management/:tab?" {...UserAccountManagementPageEntryPoint} />
        </Route>
      </Route>
      <Route element={<RoutePermissions permission={() => ENABLE_COGNITO_LOGIN} />}>
        <Route element={<SettingsPageRoute sectionName={SectionName.SFTP_ACCOUNT_MANAGEMENT} />}>
          <Route path="sftp-account-settings" {...SftpAccountSettingsPageEntryPoint} />
        </Route>
      </Route>

      <Route
        element={
          <RoutePermissions
            permission={(checkPermission) =>
              checkPermission(Permission.FeatureFlagsManagementAccess)
            }
          />
        }
      >
        <Route element={<SettingsPageRoute sectionName={SectionName.FEATURE_FLAGS} />}>
          <Route path="feature-flags" {...FeatureFlagsPageEntryPoint} />
        </Route>
      </Route>

      <Route path="custom-company-keywords" element={<CustomCompanyKeywordsPage />} />

      <Route path="*" Component={React.lazy(() => import('./NotFound'))} />
    </Route>
  );
};

export { routes };
