/**
 * @generated SignedSource<<642b79d812f748981c3127d8d1fd4152>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserAuthStatus = "authenticated" | "unauthenticated" | "unknown";
export type siteDataClientUIQuery$variables = Record<PropertyKey, never>;
export type siteDataClientUIQuery$data = {
  readonly __typename: string;
  readonly site: {
    readonly appStatus: {
      readonly errorCode: string | null;
      readonly errorMessage: string | null;
      readonly state: string;
    };
    readonly appTeardownTransaction: {
      readonly reasonMessage: string | null;
    };
    readonly authStatus: {
      readonly userAuthStatus: UserAuthStatus;
    };
    readonly companyTransition: {
      readonly companyId: string | null;
    };
    readonly degradationWarningMessage: string | null;
  };
};
export type siteDataClientUIQuery = {
  response: siteDataClientUIQuery$data;
  variables: siteDataClientUIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyTransition",
            "kind": "LinkedField",
            "name": "companyTransition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppTeardownTransaction",
            "kind": "LinkedField",
            "name": "appTeardownTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reasonMessage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthStatus",
            "kind": "LinkedField",
            "name": "authStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userAuthStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppStatus",
            "kind": "LinkedField",
            "name": "appStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorMessage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "degradationWarningMessage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "siteDataClientUIQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "siteDataClientUIQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f2e5ff04b3cc754e84fd48c378652d59",
    "id": null,
    "metadata": {},
    "name": "siteDataClientUIQuery",
    "operationKind": "query",
    "text": "query siteDataClientUIQuery {\n  __typename\n}\n"
  }
};
})();

(node as any).hash = "dc80822f7c1e4d7f67f3ba848cc8aa59";

export default node;
