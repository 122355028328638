/**
 * @generated SignedSource<<70f2964955a94ff766c14b667804be5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentCompaniesMenuSectionClientUIFragment_query$data = {
  readonly companies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly externalId: number;
        readonly id: string;
        readonly name: string;
      };
    }>;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly recentCompanies: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly externalId: number;
            readonly id: string;
            readonly name: string;
          };
        }>;
      } | null;
    };
  };
  readonly " $fragmentType": "RecentCompaniesMenuSectionClientUIFragment_query";
};
export type RecentCompaniesMenuSectionClientUIFragment_query$key = {
  readonly " $data"?: RecentCompaniesMenuSectionClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentCompaniesMenuSectionClientUIFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CompanyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentCompaniesMenuSectionClientUIFragment_query",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "CompanyConnection",
      "kind": "LinkedField",
      "name": "companies",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "companies(first:5)"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": (v0/*: any*/),
                  "concreteType": "CompanyConnection",
                  "kind": "LinkedField",
                  "name": "recentCompanies",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": "recentCompanies(first:5)"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "755ad3d89a043e3e94ba071253514110";

export default node;
