/**
 * @generated SignedSource<<5a0790088f1cce30a6ceb9e403143201>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type GenerateChallengeForAdminAccessInput = {
  email: string;
};
export type AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$variables = {
  input: GenerateChallengeForAdminAccessInput;
};
export type AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$data = {
  readonly generateChallengeForAdminAccess: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "GenerateChallengeForAdminAccessSuccess";
    readonly __typename: "GenerateChallengeForAdminAccessSuccess";
    readonly nonce: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$rawResponse = {
  readonly generateChallengeForAdminAccess: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "GenerateChallengeForAdminAccessSuccess";
    readonly nonce: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type AdminFido2ChallengeGenerateChallengeForAdminAccessMutation = {
  rawResponse: AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$rawResponse;
  response: AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$data;
  variables: AdminFido2ChallengeGenerateChallengeForAdminAccessMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "generateChallengeForAdminAccess",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nonce",
            "storageKey": null
          }
        ],
        "type": "GenerateChallengeForAdminAccessSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminFido2ChallengeGenerateChallengeForAdminAccessMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminFido2ChallengeGenerateChallengeForAdminAccessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c5b41a1adb92f429a57155b675f60c57",
    "id": null,
    "metadata": {},
    "name": "AdminFido2ChallengeGenerateChallengeForAdminAccessMutation",
    "operationKind": "mutation",
    "text": "mutation AdminFido2ChallengeGenerateChallengeForAdminAccessMutation(\n  $input: GenerateChallengeForAdminAccessInput!\n) {\n  generateChallengeForAdminAccess(input: $input) {\n    __typename\n    ... on GenerateChallengeForAdminAccessSuccess {\n      __typename\n      nonce\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f39ffc3a892af290a6d5b00ff46c358";

export default node;
