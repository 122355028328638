/**
 * @generated SignedSource<<739de3d7a7a14bebf0a1be151ba60cb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RegionDesignation = "REGION_DESIGNATION_DEFAULT" | "REGION_DESIGNATION_OTHER" | "REGION_DESIGNATION_UNKNOWN" | "%future added value";
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ACCOUNT" | "ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppCurrentUserClientUiFragment_query$data = {
  readonly company: {
    readonly __typename: string;
    readonly attentiveDomain?: string;
    readonly businessHours?: {
      readonly close: string;
      readonly open: string;
      readonly timezone: string;
    };
    readonly country?: string;
    readonly csTier?: string | null;
    readonly displayName?: string;
    readonly domain?: string | null;
    readonly externalId?: number;
    readonly id: string;
    readonly internalId?: number;
    readonly name?: string;
    readonly regions?: ReadonlyArray<{
      readonly databaseId: number;
      readonly designation: RegionDesignation;
    }> | null;
    readonly status?: string;
    readonly timezone?: string;
  };
  readonly viewer: {
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly id: string;
      readonly internalId: number;
      readonly lastName: string;
      readonly phone: string;
    };
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    };
  };
  readonly " $fragmentType": "AppCurrentUserClientUiFragment_query";
};
export type AppCurrentUserClientUiFragment_query$key = {
  readonly " $data"?: AppCurrentUserClientUiFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppCurrentUserClientUiFragment_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppCurrentUserClientUiFragment_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "companyId",
                  "variableName": "companyId"
                }
              ],
              "concreteType": "GetRolesResponse",
              "kind": "LinkedField",
              "name": "roles",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roles",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.roles"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attentiveDomain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "csTier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessHours",
                "kind": "LinkedField",
                "name": "businessHours",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "open",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "close",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "designation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e589f404dd3dc0b157a87cf62098877f";

export default node;
