/**
 * @generated SignedSource<<30d831408840c6654f8105045d0c983a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MarketingEventBannerClientUIQueryFragment_query$data = {
  readonly activeNotificationBanner: {
    readonly banner: {
      readonly callToAction: string;
      readonly description: string;
      readonly eventDate: string;
      readonly id: string;
      readonly title: string;
      readonly url: string;
    } | null;
  } | null;
  readonly " $fragmentType": "MarketingEventBannerClientUIQueryFragment_query";
};
export type MarketingEventBannerClientUIQueryFragment_query$key = {
  readonly " $data"?: MarketingEventBannerClientUIQueryFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"MarketingEventBannerClientUIQueryFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MarketingEventBannerClientUIQueryFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GetActiveNotificationBannerResponse",
      "kind": "LinkedField",
      "name": "activeNotificationBanner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationBanner",
          "kind": "LinkedField",
          "name": "banner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "callToAction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9bfd47b5ba6667ae47af77a1244598bb";

export default node;
