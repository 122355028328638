/**
 * @generated SignedSource<<5ec5426aa06d139386ad00fd0f565555>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountNameClientUIFragment_account$data = {
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentType": "AccountNameClientUIFragment_account";
};
export type AccountNameClientUIFragment_account$key = {
  readonly " $data"?: AccountNameClientUIFragment_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountNameClientUIFragment_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountNameClientUIFragment_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f33e369b0694ff9960a85dbe0b0265ef";

export default node;
