/**
 * @generated SignedSource<<fbf558019384adcd638fb408bac9b451>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HelpCenterMenuContent_Legacy_ClientUIFragment_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ZendeskLiveChatAcoreUtilsFragment_query">;
  readonly " $fragmentType": "HelpCenterMenuContent_Legacy_ClientUIFragment_query";
};
export type HelpCenterMenuContent_Legacy_ClientUIFragment_query$key = {
  readonly " $data"?: HelpCenterMenuContent_Legacy_ClientUIFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"HelpCenterMenuContent_Legacy_ClientUIFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HelpCenterMenuContent_Legacy_ClientUIFragment_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ZendeskLiveChatAcoreUtilsFragment_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2d14f40752a7e4cacca8094680cb3369";

export default node;
