/**
 * @generated SignedSource<<50cce91a77f44e1c484db126bd432f1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsMenuClientUIFragment_company$data = {
  readonly notifications: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuBadgeClientUIFragment_notificationConnection" | "NotificationsMenuContentClientUIFragment_notificationConnection" | "useReadNotificationsClientUIFragment_notificationConnection">;
  } | null;
  readonly " $fragmentType": "NotificationsMenuClientUIFragment_company";
};
export type NotificationsMenuClientUIFragment_company$key = {
  readonly " $data"?: NotificationsMenuClientUIFragment_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenuClientUIFragment_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationsMenuClientUIFragment_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationConnection",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NotificationsMenuContentClientUIFragment_notificationConnection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NotificationsMenuBadgeClientUIFragment_notificationConnection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useReadNotificationsClientUIFragment_notificationConnection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "ad9cd9843b95c7db5a6ea96d8c62e4cb";

export default node;
