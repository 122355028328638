import React, { useState } from 'react';

import {
  Box,
  BoxProps,
  Heading,
  IconButton,
  Link,
  PicnicCss,
  Separator,
  Stack,
  Text,
} from '@attentive/picnic';
import { FullscreenPanel } from '../../FullscreenPanel';
import { AccountName } from '../../../../../common/account-name';
import { CompanyName } from '../../../../../common/company-name';
import { RecentCompaniesMenuSection } from '../RecentCompaniesMenuSection';
import { EmergencyAdminMenu } from '../EmergencyAdminMenu';
import { AdminMenu } from '../AdminMenu';
import { AccountMenuVariant } from '../AccountMenu';
import { useRoles } from '@attentive/acore-utils';
import { Role } from '@attentive/data';
import { AccountMenuClientUIFragment_query$data } from '../__generated__/AccountMenuClientUIFragment_query.graphql';

interface AccountPanelMenuProps extends BoxProps {
  fragData: AccountMenuClientUIFragment_query$data;
  hasAdminUIAccess: boolean;
  inEmergencyMode: boolean;
  signOut: () => void;
  setShowSwitchCompanyDialog: (open: boolean) => void;
}

export const linkBoxCss: PicnicCss = {
  minWidth: '100%',
  padding: '$space2 $space6',
  cursor: 'pointer',
  mt: '$space2',

  '&:hover': {
    backgroundColor: '$bgRowHover',
  },
};

export const AccountPanelMenu: React.FC<AccountPanelMenuProps> = ({
  fragData,
  hasAdminUIAccess,
  inEmergencyMode,
  signOut,
  setShowSwitchCompanyDialog,
  css,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const accountRef = fragData.viewer.account;
  const companyRef = fragData.company;
  const roles = useRoles();
  const showReferAFriend = !roles.has(Role.RoleClientManagedAccount);

  return (
    <>
      <IconButton
        description="Account Menu"
        iconName="Person"
        onClick={() => setIsMenuOpen(true)}
        css={css}
      />
      <FullscreenPanel open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Box>
          <Stack css={{ mt: '$space6' }}>
            <Box css={{ px: '$space6' }}>
              <Heading as="h4" variant="sm" color="subdued">
                <AccountName accountRef={accountRef} />
              </Heading>
              <Text variant="caption" color="subdued">
                <CompanyName companyRef={companyRef} />
              </Text>
            </Box>
            <Separator />
            {hasAdminUIAccess &&
              (inEmergencyMode ? (
                <EmergencyAdminMenu variant={AccountMenuVariant.ICON_PANEL} />
              ) : (
                <AdminMenu variant={AccountMenuVariant.ICON_PANEL} />
              ))}
            <RecentCompaniesMenuSection
              queryRef={fragData}
              onMoreCompaniesClick={() => {
                setShowSwitchCompanyDialog(true);
              }}
              variant={AccountMenuVariant.ICON_PANEL}
            />
            <Separator />
            {showReferAFriend && (
              <>
                <Box css={linkBoxCss}>
                  <Link
                    href="https://info.attentivemobile.com/attentive-advocates-customer-referral/?utm_source=customer-mktg-referral-promo&utm_medium=attn-ui&utm_campaign=referral-promo-attn-ui"
                    rel="noopener noreferrer"
                    target="_blank"
                    data-nav-id="refer-a-friend"
                    css={{ textDecoration: 'none', '&:hover': { color: 'inherit' } }}
                  >
                    Refer a Friend
                  </Link>
                </Box>
                <Separator />
              </>
            )}
            <Box css={linkBoxCss} onClick={signOut}>
              Sign out
            </Box>
            <Separator />
            <Box
              css={{
                display: 'flex',
                px: '$space6',
              }}
            >
              <Link href="https://attentivemobile.com/terms/" target="_blank">
                <Text variant="micro">Terms</Text>
              </Link>
              <Text variant="micro">&nbsp;and&nbsp;</Text>
              <Link href="https://attentivemobile.com/privacy/" target="_blank">
                <Text variant="micro">Privacy</Text>
              </Link>
            </Box>
          </Stack>
        </Box>
      </FullscreenPanel>
    </>
  );
};
