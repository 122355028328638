/**
 * @generated SignedSource<<655fb14effdf2d8f168545b159e19066>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendAgentHeartbeatMutation$variables = {
  input: any;
};
export type SendAgentHeartbeatMutation$data = {
  readonly sendAgentHeartbeat: {
    readonly conciergeAgent: {
      readonly chats: ReadonlyArray<{
        readonly id: string;
        readonly latestInbound: SerializedDateTime | null;
        readonly latestOutbound: SerializedDateTime | null;
        readonly user: {
          readonly id: string;
          readonly userConversationLock: {
            readonly lockExpiration: SerializedDateTime;
            readonly lockedBy: {
              readonly firstName: string;
              readonly id: string;
              readonly lastName: string;
            } | null;
          } | null;
        } | null;
      }>;
      readonly isAvailable: boolean;
      readonly isEnrolledIntoChats: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"ConciergeAgentDetailsFragment_conciergeAgent">;
    } | null;
  } | null;
};
export type SendAgentHeartbeatMutation = {
  response: SendAgentHeartbeatMutation$data;
  variables: SendAgentHeartbeatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnrolledIntoChats",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAvailable",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ConciergeChat",
  "kind": "LinkedField",
  "name": "chats",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestInbound",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestOutbound",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserConversationLock",
          "kind": "LinkedField",
          "name": "userConversationLock",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lockExpiration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "lockedBy",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendAgentHeartbeatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendAgentHeartbeatPayload",
        "kind": "LinkedField",
        "name": "sendAgentHeartbeat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConciergeAgent",
            "kind": "LinkedField",
            "name": "conciergeAgent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConciergeAgentDetailsFragment_conciergeAgent"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendAgentHeartbeatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendAgentHeartbeatPayload",
        "kind": "LinkedField",
        "name": "sendAgentHeartbeat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConciergeAgent",
            "kind": "LinkedField",
            "name": "conciergeAgent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b1f056bc89f73fcb9cc3912d9d3359f",
    "id": null,
    "metadata": {},
    "name": "SendAgentHeartbeatMutation",
    "operationKind": "mutation",
    "text": "mutation SendAgentHeartbeatMutation(\n  $input: Empty!\n) {\n  sendAgentHeartbeat(input: $input) {\n    conciergeAgent {\n      isEnrolledIntoChats\n      isAvailable\n      chats {\n        id\n        latestInbound\n        latestOutbound\n        user {\n          id\n          userConversationLock {\n            lockExpiration\n            lockedBy {\n              id\n              firstName\n              lastName\n            }\n          }\n        }\n      }\n      ...ConciergeAgentDetailsFragment_conciergeAgent\n    }\n  }\n}\n\nfragment ConciergeAgentDetailsFragment_conciergeAgent on ConciergeAgent {\n  isEnrolledIntoChats\n  isAvailable\n  chats {\n    id\n    latestInbound\n    latestOutbound\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5744b195f8b4605ed46c558ff429996a";

export default node;
