import { createEntryPoint } from '@attentive/data-router';

import Query from '../SyncDetail/__generated__/SyncDetail_EDSLib_Query.graphql';
import SyncRunQuery from './__generated__/SyncRunDetail_EDSLib_Query.graphql';

export const SyncRunDetailEntryPoint = createEntryPoint({
  component: () => import('./SyncRunDetail'),
  getQueries: ({ params }) => {
    return {
      query: {
        parameters: Query,
        variables: {
          syncId: params.id || '',
        },
      },
      runDetailQuery: {
        parameters: SyncRunQuery,
        variables: {
          syncId: params.syncRunId || '',
        },
      },
    };
  },
});
