/**
 * @generated SignedSource<<f5d8f01a267c37dcdd07afe1c760e934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type VerifyChallengeForAdminAccessInput = {
  companyId: string;
  nonce: string;
};
export type AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$variables = {
  input: VerifyChallengeForAdminAccessInput;
};
export type AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$data = {
  readonly verifyChallengeForAdminAccess: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "VerifyChallengeForAdminAccessSuccess";
    readonly __typename: "VerifyChallengeForAdminAccessSuccess";
    readonly success: boolean;
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$rawResponse = {
  readonly verifyChallengeForAdminAccess: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "VerifyChallengeForAdminAccessSuccess";
    readonly success: boolean;
    readonly token: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type AdminFido2ChallengeVerifyChallengeForAdminAccessMutation = {
  rawResponse: AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$rawResponse;
  response: AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$data;
  variables: AdminFido2ChallengeVerifyChallengeForAdminAccessMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "verifyChallengeForAdminAccess",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "type": "VerifyChallengeForAdminAccessSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminFido2ChallengeVerifyChallengeForAdminAccessMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminFido2ChallengeVerifyChallengeForAdminAccessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f31edada211d2c3dd352faaf76ab9781",
    "id": null,
    "metadata": {},
    "name": "AdminFido2ChallengeVerifyChallengeForAdminAccessMutation",
    "operationKind": "mutation",
    "text": "mutation AdminFido2ChallengeVerifyChallengeForAdminAccessMutation(\n  $input: VerifyChallengeForAdminAccessInput!\n) {\n  verifyChallengeForAdminAccess(input: $input) {\n    __typename\n    ... on VerifyChallengeForAdminAccessSuccess {\n      __typename\n      success\n      token\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2782ada55e349450e2f58b6970b84f27";

export default node;
